import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/Meta/seo"
import { ContentWrapper } from "../components/ContentWrapper"
import { HeaderImageContainer } from "../components/HeaderImageContainer"
import { Style } from "../style"

interface Props {
  data: {
    markdownRemark: {
      html: string
    }
    file: {
      childImageSharp: {
        fluid: any
      }
    }
  }
}

const MusicPage: React.FC<Props> = ({ data }) => (
  <Layout>
    <SEO
      title="Music"
      description="Interchange was made over a period of two years between 2018-2020. Listen or purchase on Apple Music, Bandcamp and Spotify."
    />
    <HeaderImageContainer>
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="A record sleeve for the album Interchange by Jacob D’Rozario"
        fadeIn={false}
        backgroundColor={Style.color.brand}
        loading="eager"
      />
    </HeaderImageContainer>
    <ContentWrapper>
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </ContentWrapper>
  </Layout>
)

export const query = graphql`
  query MusicQuery {
    markdownRemark(frontmatter: { slug: { eq: "music" } }) {
      html
    }
    file(relativePath: { eq: "music-header.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          src
          srcSet
          sizes
          srcWebp
          srcSetWebp
        }
      }
    }
  }
`

export default MusicPage
